<template>
    <div class="account-menu">
        <span>
            <AppLink :to="$url.accountProfile()" class="account-menu__user">
                <div class="account-menu__user-avatar">
                    <img :src="user ? user.avatar_url : ''" alt="">
                </div>
                <div class="account-menu__user-info">
                    <div class="account-menu__user-name">
                        {{ user ? `${user.first_name} ${user.last_name}` : '' }}
                    </div>
                    <div class="account-menu__user-email">
                        {{ user ? `${user.email}` : '' }}
                    </div>
                </div>
            </AppLink>
            <div class="account-menu__divider" />
            <ul class="account-menu__links">
                <li>
                    <AppLink :to="$url.accountDashboard()" style="font-weight:normal">
                        My Account Login
                    </AppLink>
                </li>
                <!-- <li>
                    <AppLink :to="$url.myOrders()" style="font-weight:normal">
                        My Orders
                    </AppLink>
                </li> -->
                <!-- <li>
                    <AppLink :to="$url.accountOrders()" style="font-weight:normal">
                        Order Details
                    </AppLink>
                </li>
                <li>
                    <AppLink :to="$url.accountAddresses()" style="font-weight:normal">
                        Order History
                    </AppLink>
                </li> -->
                <li>
                    <AppLink :to="$url.accountProfile()" style="font-weight:normal">
                        Edit Profile Login
                    </AppLink>
                </li>
                <!-- <li>
                    <AppLink :to="$url.accountPassword()" style="font-weight:normal">
                        Edit Address
                    </AppLink>
                </li> -->
                <!-- <li>
                    <AppLink :to="$url.accountPassword()" style="font-weight:normal">
                        Change Password
                    </AppLink>
                </li> -->
            </ul>
            <div class="account-menu__divider" />
            <ul class="account-menu__links">
                <li>
                    <AppLink :to="$url.signOut()">
                        Logout
                    </AppLink>
                </li>
            </ul>
        </span>
    </div>
</template>

<script>
// import { Vue, Component } from 'vue-property-decorator'
import AppLink from '~/components/shared/app-link.vue'
import Cross12Svg from '~/svg/cross-12.svg'

export default {
    components: { AppLink, Cross12Svg },
    head: { title: 'Account Menu' },
    data () {
        return {
        }
    },
    computed: {
        user: {
            get () {
                return this.$store.getters['auth/getUser']
            }
        }
    },
    mounted () {
    },
    methods: {
    }
}

</script>
